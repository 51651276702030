.aws-btn.danger .aws-btn__content {
	background: #f60232 !important;
}
.aws-btn--primary.danger .aws-btn__wrapper:before {
	background: #9b001f !important;
}


.aws-btn--primary .aws-btn__content {
   
    color: #fff;
}

