@font-face {
  font-family: "MetaPro";
  src: url("./fonts/FontFont\ -\ MetaPro-Norm.otf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "MetaPro";
  src: url("./fonts/FontFont\ -\ MetaPro-NormIta.otf");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "MetaPro";
  src: url("./fonts/FontFont\ -\ MetaPro-Medi.otf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "MetaPro";
  src: url("./fonts/FontFont\ -\ MetaPro-Medi.otf");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "MetaPro";
  src: url("./fonts/FontFont\ -\ MetaPro-Norm.otf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Merchant";
  src: url("./fonts/Merchant.ttf");
  font-weight: 700;
  font-style: normal;
}

.booking-data {
  margin: auto;
  /* width: calc(100vw - 400px); */
}

.ReactSwipeableBottomSheet--open {
  max-width: calc(100vw - 362px);
}
