body {
  margin: 0;
  font-family: Roboto, MetaPro, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity));
}

.tag__footer {
    width: 80% !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.pointer {
  cursor: pointer;
}
*:focus {
  border-color: inherit !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
::-webkit-scrollbar {
  width: 0;
  height: 8px;
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  /* background: #ff0000; */
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}
.w-40 {
  width: 40% !important;
}
.w-60 {
  width: 60% !important;
}
.text__primary {
  color: #005a9e !important;
}
/*  Auth Start */
.auth-card {
  border-radius: 12px !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.auth-card-body {
  width: 35%;
  margin: 0 auto;
  padding: 30px !important;
}
.auth-title {
  font-size: 35px;
  font-weight: 600;
}
.auth-subtitle {
  font-size: 20px;
  font-weight: 400;
}
.auth-label {
  font: size 18px;
  font-weight: 300px;
  color: #000000;
}
.auth-input {
  border-radius: 5px !important;
  height: 45px;
  width: 100% !important;
  /* max-width: 400px; */
  margin-left: auto;
  margin-right: auto;
}
.auth-btn {
  border-radius: 24px !important;
  height: 50px !important;
  outline: none !important;
  width: 40% !important;
}
.error {
  padding: 10px 20px;
  background-color: red;
  border-radius: 6px;
  width: 50%;
  color: white;
}
/* Navbar */
.custom__navbar {
  background-color: #252525;
  padding: 15px;
}
.custom__width {
  width: calc(100vw - 40px);
}
.sidebar__collapse {
  width: 100% !important;
  height: 100% !important;
  background-color: #252525 !important;
  padding: 10px 14px;
  border: none !important;
  overflow: hidden;
}
.sidebar__collapse-child {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box;
}
.sidebar__container {
  display: flex;
}
@media (max-width: 1200px) {
  .sidebar__container {
    display: block;
  }
}
.sidebar__item {
  height: 180px;
  width: 200px !important;
  border-radius: 10px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: black;
  cursor: pointer;
  text-align: center;
}
.package__item {
  /* height: 140px;
  width: 300px !important;
  width: 100%;
  border-radius: 10px;
  background-color: white;
  display: flex;
  padding: 10px;
  font-weight: bold;
  font-size: 17px;
  color: black;
  cursor: pointer; */
  position: relative;
}
.package__item h6 {
  /* height: 140px;
  width: 300px !important;
  width: 100%;
  border-radius: 10px;
  background-color: white;
  display: flex;
  padding: 10px;
  font-weight: bold;
  font-size: 17px;
  color: black;
  cursor: pointer; */
  position: absolute;
  top: 75%;
  left: 5%;
  color: white;
  line-height: 150%;
  font-weight: 600;
  text-shadow: 0 2px 3px rgb(255 255 255 / 30%);
  text-shadow: 0 0 3px #000000, 0 0 5px #ffffff !important;
}
@media (min-width: 1200px) {
  .container,
  .container-xl {
    padding-left: 2%;
    padding-right: 2%;
  }
}
.sidebar__row {
  padding: 0px 50px;
  width: 46rem;
}
.sidebar__link {
  text-decoration: none;
  width: 300px !important;
}
.package__link {
  text-decoration: none;
}
.custom__nav {
  padding: 10px !important;
}
.custom__nav .nav-item {
  padding: 30px 25px !important;
  height: 80px !important;
}
.custom__nav .nav-item i {
  color: #b0b5bc !important;
}
.custom__nav .nav-item a {
  font-size: 1.125rem !important;
  font-weight: 600 !important;
  line-height: 1.25;
  color: #b0b5bc !important;
  text-decoration: none !important;
}
.custom__nav .nav-item a:hover {
  color: white !important;
}
.custom__nav .nav-item:hover {
  border-left: 2px solid #b0b5bc !important;
}
.custom__nav__active {
  border-left: 2px solid #ffc93b !important;
}
/* .image__container {
	height: 350px;
} */
.logo {
  height: 150px !important;
  float: left;
}
.menu {
  font-size: 18px;
  font-family: Roboto, MetaPro, "Segoe UI";
  font-weight: 400;
  line-height: 20px;
  color: #faf9f8 !important;
}
.page__title {
  font-family: Roboto, MetaPro, "Segoe UI";
  font-weight: 700;
  font-style: normal;
  font-size: 28px;
  line-height: 37.24px;
  color: #eff6fc !important;
}
.page__title a {
  text-decoration: none;
  color: inherit;
}
.content__section {
  /* min-width: calc(100vw - 350px); */
  height: calc(100vh - 80px);
  overflow: hidden;
  width: 100%;
}
.content__section-child {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box;
  position: relative;
}
.cart {
  z-index: 10;
  flex: 1 1 560px;
  /* margin-right: 5px; */
  /* transform: translateX(-12.5px); */
}
.cart__card.card {
  height: calc(100vh - 25px);
}
.card-header {
  background-color: #faf9f8 !important;
}
/* .cart__card .card-body {
  width: 360px;
} */
.card-footer {
  background-color: #faf9f8 !important;
}
.cart__card {
  border: 1px solid #1f6ea9 !important;
  border-radius: 8px !important;
}
.cart__title {
  font-family: Roboto, MetaPro, "Segoe UI";
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #262626;
  margin-top: 30px;
  margin-bottom: 10px;
}
.cart__subtitle {
  font-family: Roboto, MetaPro, "Segoe UI";
  font-weight: bold;
  font-size: 10px;
  line-height: 13px;
  color: #005a9e;
}
.booking__details {
  width: 100%;
  display: grid;
  gap: 20px;
  padding-right: 10px;
  grid-template-columns: 5fr 5fr;
}
.booking__summary {
  background-color: #ffffff;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.booking__summary .content {
  width: 100%;
}
.coupon__applied {
  margin-top: 30px;
  padding: 15px 0;
  border-radius: 15px;
  border: 1px dashed green;
  color: green;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.coupon__applied .coupon__code {
  font-weight: bold;
  font-size: 1.3rem;
}
.coupon__discount {
  color: green;
}
.amount__data {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 0;
  gap: 10px;
}
.amount__data > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: 1.5px solid lightgrey;
}
.amount__data > div:last-child {
  border-bottom: 0px;
}
.cart__list {
  max-height: 70vh;
  overflow-y: auto;
}
.btn__cancel {
  background: transparent !important;
  color: #1f6ea9 !important;
  padding: 10px 22px !important;
  border-color: #1f6ea9 !important;
  border-radius: 0 !important;
}
.btn__book {
  background-color: #1f6ea9 !important;
  color: white !important;
  border-radius: 0 !important;
  padding: 10px 20px !important;
  border-color: #1f6ea9 !important;
  margin-left: 10px;
}
.btn__total {
  background-color: #005a9e !important;
  border-color: #005a9e !important;
  font-weight: bold !important;
  font-size: 22px !important;
  padding: 12px 22px !important;
}
.btn__times {
  background: #005a9e !important;
  color: white;
  font-size: 12px;
  padding: 4px 5px;
  border-radius: 50%;
}
.cart__item {
  background: #ffffff;
  border: 0.5px solid #f3f2f1;
  box-sizing: border-box;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
  border-radius: 5px;
  padding: 10px;
}
.user__name {
  font-family: Roboto, MetaPro, "Segoe UI";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;

  /* Theme Black */

  color: #262626;
}
.tag__footer {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 60px;
  z-index: 9;
  background: #252525;
  color: white;
  overflow: auto;
}
.tag__footer .tags__wrapper {
  /* height: 100%; */
  /* width: calc(100% - 400px); */
  display: flex;
}
.tag__footer .tags__container {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  gap: 10px;
  padding: 10px 0 30px;
  transform: translateY(0px);
}
.tags__container::-webkit-scrollbar {
  display: none;
}
.tag__footer .tag__item {
  width: max-content;
  word-wrap: normal;
  white-space: nowrap;
  padding: 10px 15px;
  cursor: pointer;
  color: #a7a7a7;
  text-transform: capitalize;
}
.tag__item.active {
  color: white;
  border-bottom: 2px solid white;
}
.item__title {
  font-family: Roboto, MetaPro, "Segoe UI";
  font-weight: 700;
  font-size: 18px;
  line-height: 23.94px;
  color: #262626;
}
.item__details {
  font-family: Roboto, MetaPro, "Segoe UI";
  font-weight: 500;
  font-size: 12px;
  line-height: 18.62px;
  color: #262626;
}
hr {
  border: 1px solid #ebeae9 !important;
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}
.product__card {
  width: 100% !important;
  height: 200px !important;
  font-size: 24px !important;
}
.search__field {
  background-color: rgba(0, 0, 0, 0) !important;
  border: 0 solid red !important;
  color: white !important;
}
.search__field.user {
  color: black !important;
}
.spinner-border {
  margin-right: 10px;
  height: 1rem !important;
  width: 1rem !important;
}
.coupon__field {
  display: grid;
  grid-template-columns: repeat(3, 3fr);
  gap: 20px;
}
.coupon__field .coupon__item {
  --color: lightgrey;

  width: 100%;
  text-align: center;
  padding: 15px 0;
  cursor: pointer;
  color: var(--color);
  border-radius: 7px;
  border: 2px dashed var(--color);
  user-select: none;
}
.coupon__field .coupon__item.active {
  --color: #187bd1;
}
.Cal__Day__selection {
  border-radius: 0px !important;
  background-color: #015a9e !important;
}
.booking-data {
  margin: auto;
  /* width: calc(100vw - 400px); */
}
.coupon__button-group {
  width: 100%;
  display: flex;
  grid-template-columns: 5fr 5fr;
  gap: 15px;
}
.login__wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e3e3e3;
}
.login__container {
  width: 100%;
  max-width: 600px;
  padding: 40px;
  background-color: #fff;
}
.login__container h3 {
  font-weight: 400;
  font-size: 1.3rem;
}
.login__container form {
  margin: 10px 0;
}
.login__container label {
  margin-top: 15px;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  color: #718096;
}
.login__container input,
.login__container input[type="password"] {
  height: 45px;
  border-radius: 0px !important;
  border: 0px !important;
  background-color: #e3e3e3;
  font-size: 14px;
}
.login__container input:focus,
.login__container input[type="password"]:focus {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.6);
  background-color: #e3e3e3;
}
.login__container small {
  color: #718096;
  font-size: 13px;
}
.payment__container {
  padding: 10px 45px 10px 25px;
}
.user__data {
  margin: 20px 0;
  padding: 30px 20px;
  width: 100%;
  border-bottom: 2px solid lightgray;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.user__data--name {
  font-size: 24px;
  font-weight: 500;
}
.user__data b {
  color: #7f7f7f;
}
.user__data--email,
.user__data--mobile {
  color: #3e3e3e;
  font-size: 14px;
}
.user__data .status .purchase__status {
  padding: 9px 7px;
}
.purchase__status {
  --color: black;

  border-width: 1px;
  border-style: solid;
  border-color: var(--color);
  color: var(--color);

  padding: 2px 4px;
  border-radius: 6px;
}
.purchase__status.success {
  --color: green;
}
.purchase__status.failed {
  --color: red;
}
.purchase__status.pending {
  --color: #e0d900;
}
.menu__header {
  /* width: 50%; */
  padding: 0 20% 0 0;
  min-width: 300px;
  overflow: auto;
  margin: 0 0 0 auto;
}

.menu__header li {
  list-style: none;
  color: #1e88e5;
}
.menu__header li a {
  color: inherit;
  text-decoration: none;
}

.sidebar__header li {
  list-style: none;
  color: #1e88e5;
}
.sidebar__header li a {
  color: inherit;
  text-decoration: none;
}
.header_menu__item {
  width: 120px;
  height: 45px;
  background-color: #2e7a88;
  border-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  border: 0;
}

.aws-btn.header_menu__item .aws-btn__content {
  background: #2e7a88 !important;
}
.aws-btn--primary.header_menu__item .aws-btn__wrapper:before {
  background: #00515f !important;
}

.user_name_link {
  text-decoration: none;
}
.order__details--header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.order__details--header small {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}
.order__description {
  width: 100%;
  padding: 20px;
}
.order__description .desc {
  width: 100%;
  display: grid;
  grid-template-columns: 200px 5fr;
}
.desc > span {
  padding: 5px 0;
}
.desc > span:first-child {
  border-right: 1px solid black;
}
.desc > span:last-child {
  padding-left: 20px;
}
.order__error {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 767px) {
  .auth-card-body {
    padding: 80px 20px !important;
  }
  .auth-input {
    width: 100% !important;
  }
  .auth-btn {
    width: 100% !important;
  }
  .error {
    width: 100%;
  }
  .menu__header li {
    display: none;
  }
  .page__title {
    display: none;
  }
  .username {
    display: none;
  }
}

.payment-method::-webkit-scrollbar {
  height: 10px;
}

.payment-method::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #1e88e5;
}

.payment-method {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.7rem;
  padding-top: 5px;
  height: 80px;
  overflow: auto;
  max-width: 440px;
}

@media only screen and (min-width: 1240px) {
  .payment-method {
    min-width: unset;
    max-width: 550px;
  }
}

.payment-card {
  background-color: #fff;
  border-radius: 0.5em;
  position: relative;
}

.radio {
  display: none;
}

.payment-icon {
  position: absolute;
  bottom: 75%;
  left: 94%;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #005a9e;
  display: none;
}

.payment-details {
  border: 3px solid #e2ebf6;
  border-radius: 0.5em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 1rem;
  transition: border-color 0.2s ease-out;
  width: 200px;
}

.radio:checked ~ .payment-details .payment-icon {
  display: block;
}

.radio:checked ~ .payment-details {
  border: 3px solid #005a9e;
  color: white;
}

.payment-type {
  color: black;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1em;
  margin-top: 2px;
}

.dayclosure__table .py-4 {
  text-align: center;
  border-right: 1px solid #cdcdcd82;
  border-top: 1px solid #cdcdcd82;

  padding: 0px 15px;
}

.dayclosure__table .py-3 {
  text-align: center;
  border-right: 1px solid #cdcdcd82;
  border-top: 1px solid #cdcdcd82;
  padding: 15px;
}

.dayclosure__table table {
  overflow: scroll;
  width: max-content;
}

@media print {
  @page {
    margin-top: 0;
    margin-bottom: 0;
  }
  body {
    padding-top: 72px;
    padding-bottom: 72px;
  }
}

.username {
  font-family: Roboto, MetaPro, "Segoe UI";
  font-weight: 500;
  font-style: normal;
  font-size: 24px;
  line-height: 50px;
  color: #eff6fc !important;
}

.sidebar__collapse {
  background-image: url("./assets/images/menu-image.jpg");
  background-size: 150rem;
}

.sidebar__item {
  border: 2px solid #ededed;
  border-radius: 5px;

  border-color: rgb(226 232 240);
  border-width: 2px;
}

.sidebar__item:hover {
  border-color: transparent;
  background: linear-gradient(#ffffff, #ffffff) padding-box,
    linear-gradient(to top, #818cf8, #22d3ee, #0ea5e9) border-box;
}

.package-spinner {
  width: 5rem !important;
  height: 5rem !important;
}

/* .package__item {
  border: 2px solid #ededed;
  border-radius: 10px;

  border-color: rgb(226 232 240);
  border-width: 2px;
} */

/* .package__item:hover {
  border-color: transparent;
  background: linear-gradient(#ffffff, #ffffff) padding-box,
    linear-gradient(to top, #818cf8, #22d3ee, #0ea5e9) border-box;
} */
